import React, { useEffect } from 'react';
import { buildQaId } from 'utils/build-qa-id';
import { getModalStyle } from 'services/utils/styles-service';
import { CircularLoadingButton } from 'components/circular-loading-button/circular-loading-button';
import { useForm } from 'react-hook-form';
import { change, Field, InjectedFormProps, reduxForm, reset } from 'redux-form';
import AutocompleteMinigrid from 'components/form/field/render-minigrid-autocomplete';
import { required } from 'components/form/validation/validation';
import { nameOfFactory } from 'utils/types-util';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { useDispatch } from 'react-redux';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { Grid, Button, Typography, Modal } from '@mui/material';
import { renderTextField } from 'components/form/field/redux-field';
import { fetchGpis, specialtyTypeFormatter } from 'services/utils/therapy-service';
import { logger } from '../../../../../winston-logger';
import { styles } from './add-supportive-care-plans-modal.styles';
import { FORM_NAME } from './constants';
import { IChild } from '../tables/types';

interface IAddNewIcdModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  editingSupportiveCarePlansItem: IChild | undefined;
}

interface IFormFieldsDefaultValues {
  therapy: any;
  genericName: string | null;
  gpi10: string;
  medicationRecomendation: string;
}

const formFieldsValues: IFormFieldsDefaultValues = {
  therapy: null,
  genericName: null,
  gpi10: '',
  medicationRecomendation: '',
};

interface IFormFields {
  therapy: any;
  genericName: string;
  gpi10: string;
  medicationRecomendation: string;
}

type IAddNewIcdModalFormProps = IAddNewIcdModalProps &
  InjectedFormProps<IFormFields, IAddNewIcdModalProps>;

const getFieldName = nameOfFactory<IFormFields>();

const getQaId = buildQaId('application-manager.add-new-supportive-care-plans-modal', '.');
const requiredErrorMsg = 'Required';

const AddNewSupportiveCarePlansModal = (props: IAddNewIcdModalFormProps) => {
  const { onSuccess, onCancel, handleSubmit } = props;
  const classes: any = styles();
  const form = useForm<any>({});
  const dispatch = useDispatch();

  const [formValues, setFormValues] = React.useState<IFormFieldsDefaultValues>(formFieldsValues);
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (formValues: IFormFields): Promise<void> => {
    if (props.editingSupportiveCarePlansItem) {
      await editSupportiveCarePlansItem(formValues);
    } else {
      await submitSupportiveCarePlansItem(formValues);
    }
  };

  const submitSupportiveCarePlansItem = async (formValues: IFormFields): Promise<void> => {
    try {
      const gpi = formValues.gpi10;
      const medicationRecomendations = Array.from(
        new Set(formValues.medicationRecomendation.split(';').map(t => t.trim())),
      );
      if (gpi && medicationRecomendations.length) {
        const payload = {
          gpi: gpi,
          medicationRecomendations: medicationRecomendations,
        };
        const result = await ApplicationManagerClient.addSupportiveCarePlan(payload);
        if (result.data.success === true) {
          dispatch(notifySuccess('Saved'));
          dispatch(reset(FORM_NAME));
          props.onSuccess();
          props.onCancel();
        } else {
          dispatch(notifyError(result.data.result));
          props.onCancel();
        }
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError(`Error creating supportive care plan: ${error}`));
    }
  };

  const editSupportiveCarePlansItem = async (formValues: IFormFields): Promise<void> => {
    try {
      const gpi = formValues.gpi10;
      const medicationRecomendations = Array.from(
        new Set(formValues.medicationRecomendation.split(';').map(t => t.trim())),
      );
      if (gpi && medicationRecomendations.length && props.editingSupportiveCarePlansItem?.id) {
        const payload = {
          id: props.editingSupportiveCarePlansItem.id,
          gpi: gpi,
          medicationRecomendations: medicationRecomendations,
        };
        const result = await ApplicationManagerClient.updateSupportiveCarePlan(payload);
        if (result.data.success === true) {
          dispatch(notifySuccess('Saved'));
          dispatch(reset(FORM_NAME));
          props.onSuccess();
          props.onCancel();
        } else {
          dispatch(notifyError(result.data.result));
          props.onCancel();
        }
      }
    } catch (error) {
      logger.error(error);
      dispatch(notifyError(`Error Editing supportive care plan: ${error}`));
    }
  };

  const setCommaSeparatedValues = (e: any, separator = ','): void => {
    if (e.key === 'Enter') {
      e.target.value = e.target.value.trim();
      if (e.target.value !== '') {
        e.target.value = `${e.target.value.replace(/(^[,\s]+)|([,\s]+$)/g, '')}${separator} `;
      }
    } else {
      e.target.value = e.target.value.replace(/(^[,]+)|([,]+$)/g, '');
    }
  };

  const setFieldValue = (field: string, value: any) => dispatch(change(FORM_NAME, field, value));

  const handleFetchGpisOptions = (searchText: string) =>
    // eslint-disable-next-line no-confusing-arrow
    fetchGpis(searchText).then((result: any) =>
      result.data
        ? result.data.drugs.map((therapy: any) => ({
            ...therapy,
            id: therapy.ndc,
            label: therapy.drug_info,
          }))
        : [],
    );

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        if (props.editingSupportiveCarePlansItem) {
          const child = props.editingSupportiveCarePlansItem;
          const tempTherapy = await handleFetchGpisOptions(child.gpi);
          if (tempTherapy && tempTherapy.length) {
            const selectedTherapy = tempTherapy[0];
            const tempGenericName = (selectedTherapy.associatedDrugs || []).find(
              (t: any) => t.brand_name_code === 'G',
            );
            setFieldValue('therapy', selectedTherapy);
            if (tempGenericName && Object.keys(tempGenericName).length) {
              setFieldValue('genericName', tempGenericName.drug_name);
            }
          }
          setFieldValue('gpi10', child.gpi);
          setFieldValue('medicationRecomendation', child.treatment);
        }
      } catch (err) {
        logger.error(err);
        dispatch(notifyError('Error when editing Supportive Care Plan'));
      } finally {
        setLoading(false);
      }
    })();
  }, [props.editingSupportiveCarePlansItem]);

  return (
    <Modal open={props.open} data-qa-id={getQaId('wrapper')}>
      <div style={getModalStyle()} className={classes.modal}>
        <Grid container direction="column" spacing={2}>
          {/* Title */}
          <Grid item xs="auto">
            <Typography className={classes.title} data-qa-id={getQaId('title')}>
              {!props.editingSupportiveCarePlansItem
                ? 'Add Supportive Care Plan'
                : `${props.editingSupportiveCarePlansItem.medicationName} - Supportive Care Plan`}
            </Typography>
          </Grid>
          <Grid item xs={12} data-qa-id={getQaId('form')}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography className={classes.therapyLabel}>
                  Therapy Requiring Supportive Care *
                </Typography>
                <Field
                  name="therapy"
                  disabled={false}
                  label=""
                  component={AutocompleteMinigrid}
                  validate={[required]}
                  fetchOptions={handleFetchGpisOptions}
                  hint="Search by Therapy or GPI or NDC"
                  columnsToShow={{
                    gpi: 'GPI',
                    drug_name: 'Drug Name',
                    dosage_form_description: 'Form',
                    dose: 'Dose',
                    ldd: 'LDD',
                    specialty_type: 'Specialty Type',
                    ndc: 'NDC',
                  }}
                  minSearchLength={2}
                  qaId="therapy_name"
                  valueFormatter={specialtyTypeFormatter}
                  onChange={(drug: any) => {
                    if (drug) {
                      const tempGeneric = drug.associatedDrugs.find(
                        (t: any) => t.brand_name_code === 'G',
                      );
                      if (tempGeneric.drug_name && Object.keys(tempGeneric.drug_name).length) {
                        setFieldValue('genericName', `${tempGeneric.drug_name}`);
                        setFieldValue('gpi10', drug.gpi.slice(0, 10));
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="genericName"
                  label="Generic Name"
                  component={renderTextField}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="gpi10"
                  label="GPI-10 *"
                  component={renderTextField}
                  disabled={false}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="medicationRecomendation"
                  label={`Supportive Care Medication Recomendation(s) * - ${
                    props.editingSupportiveCarePlansItem
                      ? 'separate with semicolon '
                      : 'Press enter to add a new recomendation'
                  }`}
                  component={renderTextField}
                  disabled={false}
                  onKeyUp={(e: any) => {
                    setCommaSeparatedValues(e, ';');
                  }}
                  validate={[required]}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      data-qa-id={getQaId('button.cancel')}
                      onClick={() => {
                        dispatch(reset(FORM_NAME));
                        props.onCancel();
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <CircularLoadingButton
                      data-qa-id={getQaId('button.save')}
                      buttonLabel="Save"
                      onClick={handleSubmit(onSubmit)}
                      isLoading={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export const AddNewSupportiveCarePlansModalForm = reduxForm<IFormFields, IAddNewIcdModalProps>({
  form: FORM_NAME,
})(AddNewSupportiveCarePlansModal);
