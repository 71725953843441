import React from 'react';
import { BaseTable } from 'containers/application-manager/base-table/base-table';
import { Grid } from '@mui/material';
import { Edit as EditPencilIcon } from '@mui/icons-material';
import { IResponse, IResult, IChild } from './types';
import { IHeaderCell } from '../../../base-table/types';
import { AddNewSupportiveCarePlansModalForm } from '../modal/add-supportive-care-plans-modal';

interface ITableProps {
  searchTerm: string | undefined;
  triggerReload: Date | undefined;
  dataToList: IResult[];
  userHasPermissionToEditCarePlans: boolean;
  activeAnyField: boolean;
  setReloadDataTrigger: (date: Date | undefined) => void;
}

const childPkSelector = (child: IChild) => child.gpi;
const parentPkSelector = (parent: IResult) => parent.gpi;

export const DataTable: React.FC<ITableProps> = (props): JSX.Element => {
  const { setReloadDataTrigger, userHasPermissionToEditCarePlans } = props;
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [editSupportiveMed, setEditSupportiveMed] = React.useState<IChild | undefined>(undefined);

  const columnSettings: IHeaderCell<IResult, IChild>[] = [
    {
      label: 'Medication Name',
      parentKey: 'medicationName',
      childKey: 'medicationName',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Associated GPI',
      parentKey: 'gpi',
      childKey: 'gpi',
      hideLeftPadding: true,
      sortable: true,
    },
    {
      label: 'Supportive Care Treatment',
      parentKey: 'treatment',
      childKey: 'treatment',
      hideLeftPadding: true,
      sortable: true,
      childComponent: (parent, child) => {
        return (
          <Grid container direction="column">
            <Grid container justifyContent="space-between" alignItems="flex-end">
              <Grid container item xs={userHasPermissionToEditCarePlans ? 11 : 12}>
                {child.treatment}
              </Grid>
              {userHasPermissionToEditCarePlans && (
                <Grid container item xs="auto">
                  <EditPencilIcon
                    onClick={e => {
                      e.stopPropagation();
                      setEditModalOpen(true);
                      setEditSupportiveMed(child);
                    }}
                    fontSize="medium"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const getSupportiveCareData = React.useMemo<IResponse | undefined>(() => {
    const supportiveCareData = props.dataToList;
    if (supportiveCareData.length) {
      const dataToShow = supportiveCareData.map(item => {
        return {
          ...item,
        };
      }, [] as IResult[]);
      return { totalCount: dataToShow.length, results: dataToShow };
    }
    return undefined;
  }, [props.dataToList]);

  const renderEnableSupportiveCarePlansModal = (): JSX.Element => {
    return (
      <AddNewSupportiveCarePlansModalForm
        open={editModalOpen}
        onCancel={() => {
          setEditModalOpen(false);
          setEditSupportiveMed(undefined);
        }}
        onSuccess={() => {
          setReloadDataTrigger(new Date());
        }}
        editingSupportiveCarePlansItem={editSupportiveMed}
      />
    );
  };

  return (
    <>
      {renderEnableSupportiveCarePlansModal()}
      <BaseTable<IResponse, IResult, IChild>
        actionsPermitted
        orderByDefaultChild="medicationName"
        dataSet={getSupportiveCareData}
        columnSettings={columnSettings}
        orderByDefaultParent="medicationName"
        paginationQueryParamSettings={{
          pageSizeQueryStringKey: 'pageSize',
          pageNumberQueryStringKey: 'pageNumber',
          searchTermQueryStringKey: 'searchTerm',
          sortPropQueryStringKey: 'sortProp',
          sortOrderQueryStringKey: 'sortOrder',
        }}
        searchTerm={JSON.stringify({
          type: 'MULTIPLE',
          search: [
            {
              values: props.searchTerm,
              columns: ['medicationName', 'gpi'],
              fullMatch: false,
              active: props.activeAnyField,
            },
          ],
        })}
        childPkSelector={childPkSelector}
        parentPkSelector={parentPkSelector}
        triggerReload={props.triggerReload}
        qaId="supportive-care-plan"
      />
    </>
  );
};
