import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import React, { useEffect, useState } from 'react';
import {
  archiveInfo,
  assignRankingAndKeys,
  updatePhoneSmsAttributes,
} from 'services/utils/demographic-service';
import { AxiosPromise } from 'axios';
import { buildQaId } from 'utils/build-qa-id';
import {
  change,
  FieldArray,
  getFormValues,
  InjectedFormProps,
  reduxForm,
  reset,
  untouch,
} from 'redux-form';
import { compose } from 'recompose';
import { editPatient } from 'actions/action-patient';
import { getModalStyle } from 'services/utils/styles-service';
import { Divider, Grid, Modal, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { IState } from 'interfaces/redux/IState';
import { nameOfFactory } from 'utils/types-util';
import { PatientUtil } from 'utils/patient-util';
import SecureLinkAutomationField from 'containers/patient/demographics/secure-link-automation';
import { renderPhones } from 'components/form/subform/subform';
import { ViewModeEnum } from 'components/add-email-modal/add-email-modal';
import { secureLinkAutomationStatus } from 'constants/lists';
import { connect, useDispatch, useSelector } from 'react-redux';
import { resolveAudit } from 'actions/action-audit';
import { MobilePhoneSmsEnum, PhoneUseEnum } from 'constants/enums';
import { shouldResolveSMSAudit } from 'containers/patient/demographics/patient-form';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { ENSURE_SMS_ADDRESSED } from 'constants/index';
import { styles } from '../add-address-modal/add-address-modal.styles';
import { IProps } from './interfaces/IProps';
import { IFormProps } from './interfaces/IFormProps';

const FORM_ID = 'edit-phones-sms-preferences-modal';

// #region interfaces for the component
interface IAddPhoneModalProps extends IProps, InjectedFormProps<IFormProps> {}
interface IStateProps {
  initialValues: Partial<IFormProps>;
}
export enum EditModalViewModeEnum {
  EditPhone,
  EditPhoneAndSmsPreferences,
}

type PhoneItem = {
  use: string;
  phone: string;
  sms: string;
  deleted?: boolean;
};

type Props = IStateProps &
  IAddPhoneModalProps & {
    viewMode: EditModalViewModeEnum;
    visible: boolean;
    onModalClose: () => void;
  };
// #endregion

// #region constant things
const qaIdBuilder = buildQaId(FORM_ID);
const nameOfFormFields = nameOfFactory<IFormProps>();

const stripDeletedPhones = ({ deleted }: { deleted?: boolean }) =>
  deleted === undefined || !deleted;
// #endregion

const EditPhonesSmsPreferencesModal: React.FC<Props> = (props: any): JSX.Element => {
  const { classes, formValues, viewMode } = props;

  const showFCSecureLinkAutomationSection = (formValues?.phones || []).some(
    (phone: PhoneItem) =>
      phone.use === PhoneUseEnum.Mobile && phone.sms === MobilePhoneSmsEnum.OptIn && !phone.deleted,
  );

  // #region component state
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [saveDisabled, setSaveDisabled] = React.useState<boolean>(false);
  // #endregion

  // #region redux
  const patient = useSelector((state: IState) => state.patient);
  const dispatch = useDispatch();
  // #endregion

  // #region functions
  const formatPhoneValue = (phoneItem: any) => {
    const formatted = {
      use: phoneItem.use,
      value: phoneItem.value,
      period: `${phoneItem.fromTime || ''}-${phoneItem.toTime || ''}`,
      sms: phoneItem.sms,
    };
    return formatted;
  };

  const handleCancel = (): void => {
    setErrorMessage('');
    setSaveDisabled(false);
    props.onModalClose();
    props.reset();
    dispatch(reset(FORM_ID));
  };

  const handleSubmit = async (formValues: any) => {
    setSaveDisabled(true);

    const { phones, ...phonesOptions } = formValues;
    const {
      is_declined_to_provide_phones: isDeclinedToProvidePhones,
      preferred_phone_index: preferredPhoneIndex,
    } = phonesOptions;

    const rankedPhones = assignRankingAndKeys(
      archiveInfo((phones || []).map(formatPhoneValue), patient.phones),
      preferredPhoneIndex,
    );

    const patientIsSMSOptIn = rankedPhones.some(
      (phone: PhoneItem) => phone.sms === MobilePhoneSmsEnum.OptIn && !phone.deleted,
    );
    const autoSecureLink = patientIsSMSOptIn ? Number(formValues.auto_secure_link) : null;
    const aslAnchorTherapyId =
      patientIsSMSOptIn && autoSecureLink === secureLinkAutomationStatus.AUTOMATION
        ? formValues.asl_anchor_therapy_id
        : null;
    updatePhoneSmsAttributes(rankedPhones, props.initialMobileNumbers);

    const payload: any = {
      ...PatientUtil.convertPatientFromStateForUpdate(patient),
      phones: JSON.stringify(rankedPhones),
      auto_secure_link: autoSecureLink,
      asl_anchor_therapy_id: aslAnchorTherapyId,
      ...phonesOptions,
    };

    if (isDeclinedToProvidePhones !== undefined) {
      payload.phones_options = phonesOptions;
    } else {
      payload.phones_options = null;
    }

    (dispatch(editPatient(payload) as unknown) as AxiosPromise<any>)
      .then(() => {
        handleCancel();

        if (shouldResolveSMSAudit(rankedPhones)) {
          resolveAuditDefinition({
            auditDefinitionType: ENSURE_SMS_ADDRESSED,
            auditState: props.auditState,
            patient,
            resolverFunction: (resolvedAudit: any) => {
              dispatch(resolveAudit(resolvedAudit));
            },
          });
        }
      })
      .catch(error => {
        setSaveDisabled(false);
        setErrorMessage('Could not update phone preferences');
      });
  };

  // #endregion

  return (
    <Modal open={props.visible} data-qa-id={qaIdBuilder('modal')}>
      <form data-qa-id={qaIdBuilder(FORM_ID)} autoComplete="off">
        <div
          style={{ ...getModalStyle(), maxHeight: '50rem', overflowY: 'scroll' }}
          className={classes.addModal}
        >
          <Grid container>
            {errorMessage && (
              <Grid item xs={12} className={classes.row}>
                <Typography className={classes.error}>{errorMessage}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={classes.row}>
            <Typography variant="h6" className={classes.heading}>
              {viewMode === EditModalViewModeEnum.EditPhoneAndSmsPreferences
                ? 'Edit Phones And SMS Preferences'
                : 'Edit Phones'}
            </Typography>
          </Grid>

          <FieldArray
            name="phones"
            formValues={formValues}
            preferredField="preferred_phone_index"
            declineField="is_declined_to_provide_phones"
            component={renderPhones}
            change={(fieldName: string, fieldValue: any) => {
              dispatch(change(FORM_ID, fieldName, fieldValue));
            }}
            viewMode={ViewModeEnum.PATIENT_OVERVIEW}
            classes={classes}
          />

          {showFCSecureLinkAutomationSection ? (
            <Grid item xs={12} className={classes.row} style={{ paddingTop: '20px' }}>
              <SecureLinkAutomationField formValues={formValues} />
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <ConfirmationPanel
              handleSubmit={props.handleSubmit(handleSubmit)}
              handleCancel={handleCancel}
              disableSubmit={saveDisabled}
            />
          </Grid>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = (state: any): any => {
  const { patient, audit } = state;

  const initialValues = {
    phones: patient.phones?.filter(stripDeletedPhones) || [],
    preferred_phone_index: patient.phones?.findIndex(({ rank }: { rank: number }) => rank === 1),
    auto_secure_link: patient.auto_secure_link,
    asl_anchor_therapy_id: patient.asl_anchor_therapy_id,
    is_declined_to_provide_phones: patient.is_declined_to_provide_phones,
  };
  const formValues = getFormValues(FORM_ID)(state) || initialValues;

  return {
    form: FORM_ID,
    initialValues,
    patient,
    formValues,
    auditState: audit,
    enableReinitialize: true,

    initialMobileNumbers: initialValues.phones
      .filter(({ use }: { use: string }) => use === 'Mobile')
      .map(({ value }: { value: string }) => value),
  };
};

export default compose<any, any>(
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({}),
)(EditPhonesSmsPreferencesModal);
