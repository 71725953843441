import React, { useState } from 'react';
import { Grid } from '@mui/material';
import cx from 'classnames';
import Button from 'components/questionnaire/components/button';
import WhiteContainer from '../../components/white-container';

import {
  NewAllergies,
  SideEffects,
  MedicationChanges,
  MissedDoses,
  PatientQuestions,
  NewConditions,
  HospitalVisits,
  NewInfections,
} from '../questions/clinical_questions';
import PickUpDates from '../questions/pickup_questions';
import PaymentMethod from '../questions/payment_method_question';
import IncludedTherapies from '../questions/included-therapies';
import PickUpOrDelivery from '../questions/pickup_or_delivery_question';
import DeliveryQuestions from '../questions/delivery_questions';
import AncillarySupplies from '../questions/ancillary_supplies_question';
import Header from '../../components/header';
import ProgressContainer from '../../components/progress-container';
import SubHeader from '../../components/sub-header';
import { STRINGS } from '../../fc_ss_constants';

const NUMBER_OF_QUESTIONS_ANCILLARY = 5;
const NUMBER_OF_QUESTIONS_SPECIALTY = 12;
const questionList = [
  <IncludedTherapies />,
  <AncillarySupplies />,
  <NewAllergies specialtyRequired name="new_allergies" />,
  <SideEffects specialtyRequired />,
  <MedicationChanges specialtyRequired />,
  <MissedDoses qa={2} />,
  <NewConditions specialtyRequired />,
  <HospitalVisits specialtyRequired />,
  <NewInfections specialtyRequired />,
  <PatientQuestions specialtyRequired />,
  <PickUpOrDelivery qa={3} />,
  <PickUpDates name="pickup_dates" qa={4} qs={11} />,
  <DeliveryQuestions name="delivery_questions" qa={4} qs={11} />,
  <PaymentMethod name="payment_method" qa={5} qs={12} />,
];

const QuestionsPage = ({ submit, store, classes, skipToPage }) => {
  const [questionIndex, setQuestionIndex] = useState({ index: 0, subQ: 0 });
  const [patientAnswers, setPatientAnswers] = useState({ ...store.patientAnswers });
  const [returnToReviewPage, setReturnToReviewPage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveAnswer = response => {
    if (!Array.isArray(response)) {
      response = [response];
    }
    const newPatientAnswers = { ...patientAnswers };
    response.forEach(item => {
      if (/_details$/.test(item.keyName)) {
        if (item.value) {
          if (!newPatientAnswers.clinical_responses) {
            newPatientAnswers.clinical_responses = { [item.keyName]: item.value };
          } else {
            newPatientAnswers.clinical_responses[item.keyName] = item.value;
          }
        } else {
          delete newPatientAnswers?.clinical_responses?.[item.keyName];
          if (Object.keys(newPatientAnswers?.clinical_responses || {}).length === 0) {
            delete newPatientAnswers.clinical_responses;
          }
        }
      } else {
        newPatientAnswers[item.keyName] = item.value;
      }
    });
    const allSpecialtyNonPRNTherapiesSelected = newPatientAnswers.therapies.every(
      item => item.included || !item.is_specialty || item.as_needed,
    );
    const allTherapiesSelected = newPatientAnswers.therapies.every(item => item.included);
    newPatientAnswers.patient_contact_required = !allSpecialtyNonPRNTherapiesSelected ? 1 : 0;
    newPatientAnswers.patient_requested_therapy_changes = !allTherapiesSelected ? 1 : 0;
    setPatientAnswers(newPatientAnswers);
  };

  const findNextAncillaryOnlyQuestionIndex = startIndex => {
    for (let i = startIndex + 1; i < questionList.length; i += 1) {
      if (!questionList[i].props.specialtyRequired) {
        return i;
      }
    }
  };

  const nextQuestion = (index, fullNext) => {
    const onlyAncillaries = store.onlyAncillaryTherapies;
    // sending a subQ value of -1 to a multi-page question signals the
    // question to cycle through all the questions when answering while
    // on the review page.
    const subQ = fullNext ? -1 : 0;
    if (returnToReviewPage && typeof index !== 'string') {
      setQuestionIndex({ index: -1, subQ });
      setReturnToReviewPage(false);
      return;
    }
    if (typeof index === 'string') {
      const questionNumber = questionList.findIndex(q => q.props.name === index);
      index = questionNumber;
    }
    if (index !== undefined) {
      setQuestionIndex({ index, subQ });
    } else if (questionIndex.index < questionList.length - 1) {
      let nextQuestion = questionIndex.index + 1;
      if (onlyAncillaries) {
        nextQuestion = findNextAncillaryOnlyQuestionIndex(questionIndex.index);
      }
      setQuestionIndex({ index: nextQuestion, subQ });
    } else {
      setQuestionIndex({ index: -1, subQ });
    }
  };

  const editQuestionAndReturnToReview = (index, subQ) => {
    setQuestionIndex({ index, subQ });
    setReturnToReviewPage(true);
  };

  const skipQuestion = () => {
    setQuestionIndex({ index: questionIndex.index + 1, subQ: 0 });
  };

  const clinicalQuestions = [
    'new_allergies',
    'side_effects',
    'changes_in_medication',
    'patient_missed_doses',
    'patient_questions',
    'new_medical_conditions',
    'hospital_visit',
    'new_infections',
  ];

  const validateResponse = () => {
    return (
      clinicalQuestions.every(key => patientAnswers[key] !== null) &&
      (patientAnswers.pickup_or_delivery === STRINGS.pickup ||
        (patientAnswers.pickup_or_delivery === STRINGS.delivery &&
          patientAnswers.preferred_shipping_address &&
          patientAnswers.preferred_shipping_address.label !== STRINGS.answer_required)) &&
      patientAnswers.paymentMethod !== null &&
      patientAnswers.paymentMethod !== STRINGS.answer_required
    );
  };

  const renderCurrentQuestion = () => {
    // this returns one of the questions
    if (questionIndex.index >= 0) {
      const component = questionList[questionIndex.index];
      return React.cloneElement(component, {
        saveAnswer,
        isReview: false,
        answerOptions: store.answerOptions,
        currentAnswers: patientAnswers,
        isEdit: returnToReviewPage,
        skipQuestion,
        skipToPage,
        store,
        questionIndex,
        nextQuestion,
        classes,
      });
    }
    // if questionIndex is negative then the review page is rendered
    const enableSubmit = validateResponse();

    const localSubmit = () => {
      setIsSubmitting(true);
      submit(patientAnswers);
    };

    return (
      <>
        <Grid>
          <Grid container spacing={3.5}>
            {questionList.map((q, index) => {
              if (
                !store.onlyAncillaryTherapies ||
                (store.onlyAncillaryTherapies && !q.props.specialtyRequired)
              ) {
                return React.cloneElement(q, {
                  classes,
                  key: index,
                  isReview: true,
                  currentAnswers: patientAnswers,
                  answerOptions: store.answerOptions,
                  questionIndex,
                  onEdit: subQ => editQuestionAndReturnToReview(index, subQ),
                });
              }
              return null;
            })}
          </Grid>
        </Grid>
        <Button
          className={classes.confirmButton}
          disabled={!enableSubmit || isSubmitting}
          onClick={localSubmit}
          data-qa-id="confirm"
        >
          Confirm & Send
        </Button>
      </>
    );
  };

  // After question number 10 the questions branch depending on the answer from question 10
  // so have to adjust the question number to correct for the branching.
  let questionNumber;
  if (store.onlyAncillaryTherapies) {
    questionNumber = questionList[questionIndex.index]?.props?.qa || questionIndex.index + 1;
  } else {
    questionNumber = questionList[questionIndex.index]?.props?.qs || questionIndex.index + 1;
  }
  const number_of_questions = store.onlyAncillaryTherapies
    ? NUMBER_OF_QUESTIONS_ANCILLARY
    : NUMBER_OF_QUESTIONS_SPECIALTY;

  return (
    <WhiteContainer>
      <Header title="It's time for your refill" />
      {questionIndex.index >= 0 && !returnToReviewPage ? (
        <ProgressContainer index={questionNumber} length={number_of_questions} />
      ) : (
        <SubHeader title={returnToReviewPage ? 'Edit response' : 'Please, review your responses'} />
      )}
      <div
        className={cx(
          questionIndex.index >= 0 && classes.questionContentContainer,
          classes.questionContent,
        )}
      >
        {renderCurrentQuestion()}
      </div>
    </WhiteContainer>
  );
};

export default QuestionsPage;
